import { AllPage } from "./AllPage";
import { CountriesPage } from "./CountriesPage";
import { GovermentsPage } from "./GovermentsPage";
import { TopPage } from "./TopPage";

export const MainPage = () => {

    return (
        <div>
            <CountriesPage />
        </div>
    );
}