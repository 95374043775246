import { Link } from "react-router-dom";
import { DHondt, GROUPS, getPrediction, getPredictionSeats, getPredictionSeatsByGroup, pollsFunc } from "../calculations/calc";
import { getCoalitions } from "../calculations/calculationDivs";
import { styleByGroup } from "../calculations/utils";
import { StackBar2Graph } from "../components/StackBar2Graph";
import parties from '../data/parties.json';
import { getAndSetFirstDayOfMonth } from "./CookieData";
import { PredictionCountry } from "./PredictionCountry";


export const PredictionEu = () => {
    const partyFiltered = (country) => parties.filter(x => x.country === country && !x.region)[0];
    const euSeatsFunc = (country) => partyFiltered(country).seats;
    const countries = parties.filter(x => !x.region).map(x => x.country);

    const prediction = countries.map(x => { return { label: x, data: getPredictionSeatsByGroup(x) };});
    
    const getSumItem = (x, i) => {
        const prev = getAndSetFirstDayOfMonth("group_" + x.label, x.data);
        const diff = x.data - prev;

        return <div key={i} className="wrap">
            <div className={x.clName} key={i}>
                {x.label}: {x.data}
            </div>
            {(diff > 0 || diff < 0) && 
                <span className={x.clName + " rounded"}>{diff > 0 ? "+" : ""}{diff}</span>}
        </div>
    }
    const getSum = () => {
        const sum = ["Left", "G", "S&D", "RE", "EPP", "ECR", "ID", "NI"]
            .map((x, i) => { return { clName: "highlight " + styleByGroup(x), label: x, data: prediction.reduce((s, a) => s + a.data[i], 0) }; });
        return <div className="wrap">{sum.map((x, i) => getSumItem(x, i))}</div>
    }
    const getLastUpdate = () => {
        const allPolls = countries.map(x => pollsFunc(x)[0]).filter(x => x?.date).map(x => x.date);
        const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
        const dt = Math.max(...allPolls.map(x => new Date(x.replace(pattern,'$3-$2-$1'))));

        return <div className="highlight groupNi">last update {new Date(dt).toLocaleDateString('de')}</div>
    }
    const euSeats = countries.map(x => euSeatsFunc(x)).reduce((s, a) => s + a, 0);
    const getHeader = () => {
        return <div className="wrap">
            {euSeats &&
                <div className="highlight hlFirst">seats: {euSeats}</div>
            }
            {euSeats &&
                <div className="highlight hlFirst">majority: {Math.floor(euSeats / 2) + 1}</div>
            }
            {getSum()} &nbsp;|&nbsp; 
            {getLastUpdate()}
            &nbsp;|&nbsp;
        </div>
    }
    const title = "EU election prediction [seats]";
    return <div>
        <ElectionResultLink />
        {getHeader()}
        <>
            <span className="underline">coalitions</span>
            {getCoalitions(euSeats, ["S&D", "RE", "EPP"], 
                GROUPS.map((x, i) => prediction.reduce((s, a) => s + a.data[i], 0)), 
                GROUPS)}
        </>
        {<StackBar2Graph title={title} labels={["Left", "G", "S&D", "RE", "EPP", "ECR", "ID"]}
                        dataAll={prediction} />}
        <hr />
        {
            parties.filter(x => !x.region).map(x => x).sort((a, b) => b.seats - a.seats).map(x => x.short).map(x => <div key={x}>
                    <PredictionCountry country={parties.find(y => y.short === x).country} isParliament={false} />
                    <hr />
                </div>)
        }
    </div>
}

const ElectionResultLink = (props) => {
    return (
        <div>
            <br />
            <Link to={"/EU-result"}>
                <div className="highlight groupNi">
                    <span className="dot groupSD" />
                    &nbsp;Live election data and predictions...
                </div>
            </Link>
        </div>
        );
}
