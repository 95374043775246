import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';

export const SemiCircleGraph = (props) => {
    const { title, labels, dataNow, bgColor, height } = props;
    const background = bgColor ?? '#333';

    const plugin = {
        id: 'background',
        beforeDraw: (chart, args, opts) => {
          if (!opts.color) {
            return;
          }
      
          const {
            ctx,
            chartArea
          } = chart;
      
          ctx.fillStyle = opts.color;
          ctx.fillRect(chartArea.left, chartArea.top, chartArea.width, chartArea.height)
        }
      }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        plugin,
        ChartDataLabels,
        ArcElement
      );
      
      const options = {
        rotation: -90,
        circumference: 180,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: { 
            display: false
          },
          background: { color: background },

          datalabels: {
            display: true,
            color: "#fff",
            formatter: x => Math.round(x * 10)/10,
            anchor: "end",
            offset: 2,
            align: "start",
          }
        }
      };
      if (background !== '#333'){ options.animation = { duration: 0 }; }
      
      const getGroup = (label) => {
        const group = label.split('\n')[1] ?? label;
        switch (group.toLowerCase()) {
            case '(left)': return 'left';
            case '(g)': return 'g';
            case '(s&d)': return 's&d';
            case '(re)': return 're';
            case '(epp)': return 'epp';
            case '(ecr)': return 'ecr';
            case '(id)': return 'id';
            case '(ni)': return 'ni';
            default: return '';
        }
      }
      const getColor = (label) => {
        const group = getGroup(label);
        switch (group) {
          case 'left': return '#a00';
          case 'g': return '#4a4';
          case 's&d': return '#f00';
          case 're': return '#990';
          case 'epp': return '#3af';
          case 'ecr': return '#00f';
          case 'id': return '#009';
          case 'ni': return '#999';
          default: return '#fff';
        }
      }
      const datasets = 
      [{
        data: dataNow,
        backgroundColor: labels.map(x => getColor(x))
      }];
      const data = {
        labels,
        datasets: datasets,
      };

    return (
        <div style={{backgroundColor: background, paddingTop: 60, height: height }}>
            <Doughnut options={options} data={data} />
        </div>
    )
}