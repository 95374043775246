import { pollsFunc } from '../calculations/calc';
import { countryDiv, getClass } from '../calculations/calculationDivs';
import parties from '../data/parties.json';
import nextElection from '../data/nextElection.json';
import { EuropeMap } from '../items/EuropeMap';
import { Link } from 'react-router-dom';

export const AllPage = () => {
    //const pollsFunc = (country) => euPolitics.filter(x => x.country === country)[0].polls;
    const countryPartiesFunc = (country) => parties.find(x => x.country === country && !x.region)?.parties
        ?? parties.find(y => y.country === parties.find(x => x.region === country).country).parties;
    const countries = parties/*.filter(x => !x.region)*/.map(x => x.region === null ? x.country : x.region);
    
    let allPolls = countries.map(x => { return { country: x, poll: pollsFunc(x)[0] }; }).filter(x => x.poll);
    allPolls.push(...countries.map(x => { return { country: x, poll: pollsFunc(x)[1] }; }).filter(x => x.poll));
    allPolls.push(...countries.map(x => { return { country: x, poll: pollsFunc(x)[2] }; }).filter(x => x.poll));
    const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    allPolls.forEach((x, i) => { 
        x["dt"] = new Date(x.poll.date.replace(pattern,'$3-$2-$1')).getTime()
    });
    allPolls = allPolls.filter(x => { 
        var d = new Date();
        d.setDate(d.getDate() - 7);
        return d <= x.dt; 
    });
    allPolls = allPolls.sort((a, b) => b.dt - a.dt > 0 ? 1 : -1);

    const getPoll = (x, i) => {
        return <div key={i} className="hoverable">
            <div className="wrap margin8 padding8">
                <div className="highlight hlFirst">{x.poll.date}</div>&nbsp;
                {countryDiv(x.country)}
                &nbsp;|&nbsp; 
                <div className="wrap">
                    {countryPartiesFunc(x.country)?.filter(z => x.poll[z.party]).map((y, j) => 
                        <div key={j} className={"highlight " + getClass(y.group)}>{y.party}: {x.poll[y.party]}</div>)
                    }
                </div> &nbsp;|&nbsp;
                <div className="highlight groupNi">
                    {x.poll.firm}
                </div>
            </div>
        </div>
    }

    const formatDate = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString('de');
    }
    const dots = (date) => {
        const now = new Date();
        const d = new Date(date);
        const days = (d.getTime() - now.getTime())/ (1000 * 3600 * 24);
        const res = [];
        for (let i = 0; i < days; ++i) {
            res.push(<span key={i} className="dot groupNi" />);
        }
        return <span>
            &nbsp; {res.map(x => x)}
            <span className="dot groupG" />
        </span>
    }

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() - 1);
    const currentElection = nextElection.filter(x => isNaN(x.date) && new Date(x.date) <= new Date() && new Date(x.date) >= tomorrow);

    return <div>
        <EuropeMap />
        <hr />
        { currentElection && currentElection[0] &&
            <>
                <span className="underline">election today</span>
                <div className="margin8">
                    {countryDiv(currentElection[0].country)}
                </div>
            </>
        }
        <ElectionResultLink />
        <span className="underline">next election</span>
        <div className="margin8">
        {
            nextElection.filter(x => isNaN(x.date) && new Date(x.date) >= new Date()).slice(0, 10).map(x => <div key={x.country} className="margin8"><span key={x.country} className="margin8 hoverable">
                <span className="margin8">
                    {countryDiv(x.country)}
                    &nbsp;<b>{formatDate(x.date)}</b>
                    {dots(x.date)}
                </span>
                <br />
            </span></div>)
        }
        {
            [...new Set(nextElection.filter(x => !isNaN(x.date)).map(x => x.date))]
                .map(x => <div key={x} className="margin8 hoverable">
                    <b className="margin8">{x}:</b> {nextElection.filter(y => y.date === x).map(y => <span key={y.country} className="margin8">
                        {countryDiv(y.country)}
                    </span>)}
                </div>)
        }
        </div>
        <hr />
        {allPolls.length > 0 &&
            <span className="underline">latest polls</span>
        }
        {
            allPolls.map((x, i) => getPoll(x, i))
        }
        <hr />
        <a href="https://twitter.com/statostuff?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-show-count="false">Follow @statostuff</a>
    </div>
}

const ElectionResultLink = (props) => {
    return (
        <div>
            <Link to={"/EU-result"}>
                <div className="highlight groupNi">
                    <img src={"/flags/EU.png"} alt="EU election results" height={14} />&nbsp;
                    <span className="dot groupSD" />
                    &nbsp;Live election data and predictions...
                </div>
            </Link>
        </div>
        );
    }