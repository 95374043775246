import { GraphHeader } from "../items/GraphHeader"
import { PicturableGraph } from "../items/Picturable"
import euPolitics from '../data/euPolitics5.json';
import { GROUPS, getEuSeatsByGroup, getPartiesFunc, getPredictionSeatsByGroup } from "../calculations/calc";
import { DotMap } from "../items/DotMap";
import parties from '../data/parties.json';
import { DetailDotMap } from "../items/DetailDotMap";
import { Link } from "react-router-dom";

export const EuResultPage = (props) => {
    const lastElectionYear = 2019;
    const currentElectionYear = 2024;

    
    const partyFiltered = (country) => parties.filter(x => x.country === country && !x.region)[0];
    const euSeatsFunc = (country) => partyFiltered(country).seats;
    const countries = parties.filter(x => !x.region).sort((a, b) => b.seats - a.seats).map(x => { return { country: x.country, short: x.short }; });
    //console.log(countries);
    const euSeats = countries.map(x => euSeatsFunc(x.country)).reduce((s, a) => s + a, 0);
    const prediction = countries.map(x => { return { label: x.country, data: getPredictionSeatsByGroup(x.country) };});
    const results = countries.map(x => { return { label: x.country, data: getEuSeatsByGroup(x.country) };});
    //console.log("prediction", prediction);
    //console.log("result", results);
    const oldResultPerCountry = GROUPS.map(x => euPolitics.map(c => { 
        const country = c.country;
        let euElectionSeats = c.euElectionsSeats.filter(y => y.year === lastElectionYear)[0];
        const countryParty = partyFiltered(country);
        let amount = 0;
        for (const name in euElectionSeats){
            const value = euElectionSeats[name];
            const group = countryParty.parties.filter(y => y.party === name)[0]?.group;

            if (x === group){ 
                amount += value; 
                //console.log(country, name, value);
            }
        }
        return  {country, amount};
    }, 0));
    const oldResults = oldResultPerCountry.map(x => x.reduce((s, a) => {
        return s + a.amount;
    }, 0));

    
    const newResultPerCountry = GROUPS.map(x => euPolitics.map(c => { 
        const country = c.country;
        let euElectionSeats = c.euElectionsSeats.filter(y => y.year === currentElectionYear)[0];
        const countryParty = partyFiltered(country);
        let amount = 0;
        for (const name in euElectionSeats){
            const value = euElectionSeats[name];
            const group = countryParty.parties.filter(y => y.party === name)[0]?.group;

            if (x === group){ 
                amount += value; 
            }
        }
        return  {country, amount};
    }, 0));
    const newResults = newResultPerCountry.map(x => x.reduce((s, a) => {
        return s + a.amount;
    }, 0));
    //console.log(newResults);

    return (
        <div className="countryDiv">
            <DotMap groupCounts={results} oldCounts={oldResults} />

            {countries.map(x => 
                <DetailDotMap key={x.country} groupCounts={results.find(y => y.label === x.country)} 
                    oldCounts={oldResultPerCountry.map(z => z.find(y => y.country === x.country).amount)} country={x.country} short={x.short} />)
            }

            We are showing predictions until we get real election data. The association of parties into each group may and will change in weeks after the election.
            {countries.map(x => 
                <Item key={x.country} country={x.country} />
            )}
        </div>
    )
}

const Item = (props) => {
    const { country } = props;
    
    const countryParties = getPartiesFunc(country);
    const euFiltered = euPolitics.filter(x => x.country === country)[0];
    const lastElection = euFiltered.euElections[0];
    const lastElectionSeats = euFiltered.euElectionsSeats[0];

    const labels = countryParties.filter(x => lastElection[x.party]).map(x => x.party + "\n(" + x.group + ")");
    const labelsSeats = countryParties.filter(x => lastElectionSeats[x.party]).map(x => x.party + "\n(" + x.group + ")");
    const data = countryParties.map(x => lastElection[x.party]).filter(x => x);
    const dataSeats = countryParties.map(x => lastElectionSeats[x.party]).filter(x => x);

    return (
        <div>
            <div className="parentOfHalf">
                <div className="half">
                    <PicturableGraph downloadName="electionResult.jpg" title={country + " election result " + lastElection.year} labels={labels} 
                        data={data} useSemiCircleGraph={true} />
                    { euFiltered.euElections.length > 1 && 
                        <>
                            <span className="highlight hlFirst">{euFiltered.euElections[0].year} - {euFiltered.euElections[1].year}</span>
                            <GraphHeader curr={euFiltered.euElections[0]} prev={euFiltered.euElections[1]} parties={countryParties} noThreshold={true} />
                        </>
                    }
                </div>
                <div className="half">
                    <PicturableGraph downloadName="electionResultSeats.jpg" title={country + " election result seats " + lastElection.year} labels={labelsSeats} 
                        data={dataSeats} useSemiCircleGraph={true} />
                    { euFiltered.euElectionsSeats.length > 1 && 
                        <>
                            <span className="highlight hlFirst">{euFiltered.euElectionsSeats[0].year} - {euFiltered.euElectionsSeats[1].year}</span>
                            <GraphHeader curr={euFiltered.euElectionsSeats[0]} prev={euFiltered.euElectionsSeats[1]} parties={countryParties} noThreshold={true} />
                        </>
                    }
                </div>
            </div>
            <hr />
        </div>
    )
}
