import React, { useState } from 'react';
import mapEurope from '../data/mapEurope.json';
import mapSpain from '../data/mapSpain.json';
import mapGermany from '../data/mapGermany.json';
import mapItaly from '../data/mapItaly.json';
import parties from '../data/parties.json';
import euPolitics from '../data/euPolitics5.json';
import { GROUPS, getChangeSinceElection, getPrediction, getShortCountry, govPerc, groupByGroup, leftRightScore, libConScore } from '../calculations/calc';
import { getColorByGroup, getColorByGroupScore, getColorByLeftRightScore, getColorByLibConScore, getGrayColorByScore, getIndexOfMax, getLightColorByGroup, parseFloat, parseInt } from '../calculations/utils';
import { Picturable } from './Picturable';
import { getSumByParty } from '../calculations/calculationDivs';
import { useNavigate } from 'react-router-dom';

export const EuropeMap = () => {
    const [type, setType] = useState(0);
    const titles = ["the biggest group in a country", "prime minister party", "left/right score", "liberal/conservative score", "the biggest gain", "EU seats", "government popularity", "latest poll", "", "",
        "Left", "Green", "S&D", "RE", "EPP", "ECR", "ID", "NI"];
    const getDataChange = (country) => {
        return groupByGroup(getChangeSinceElection(country), country);
    }
    const dataChange = (country, filterLow = true) => {
        if (parties.find(x => x.country === country && !x.region)) {
            const change = getDataChange(country);
            if (filterLow && Math.max(...change) < 4) { return null; }
            return GROUPS[getIndexOfMax(change)];
        } else { return null; }
    }
    const dataPredict = (country) => {
        if (parties.find(x => x.country === country && !x.region)) {
            const maxGroup = GROUPS[getIndexOfMax(getPrediction(country))];
            return maxGroup;
        } else { return null; }
    }
    const dataGovPerc = (country) => {
        if (parties.find(x => x.country === country && !x.region)) {
            return Math.round(100 * govPerc(country));
        } else { return null; }
    }
    const predict = (country) => {
        if (parties.find(x => x.country === country && !x.region)) {
            return getPrediction(country)[type - 10];
        } else { return null; }
    }
    const pollAge = (country) => {
        const pol = euPolitics.find(x => x.country === country && !x.region);
        if (pol && pol.polls.length > 0) {
            const poll = pol.polls[0].dateJs;
            return Math.floor((new Date() - new Date(poll))/((1000 * 60 * 60 * 24)));
        }
        return null;
    }
    const primeMinisterGroup = (country) => {
        const pol = euPolitics.find(x => x.country === country);
        if (pol && pol.governments.length > 0 && pol.governments[0].parties && pol.governments[0].parties.length > 0) {
            const party = pol.governments[0].parties[0];
            const group = parties.find(x => x.country === country && !x.region).parties.find(x => x.party === party)?.group;
            return group;
        }
        return null;
    }
    const leftRightText = (country) => {
        const value = leftRight(country);
        if (value) {
            if (value > 0) { return `${value}% (left)`; }
            if (value < 0) { return `${-1 * value}% (right)`; }
        }
        return null;
    }
    const leftRight = (country) => {
        if (parties.find(x => x.country === country && !x.region)) {
            return leftRightScore(country);
        } else { return null; }
    }
    const libConText = (country) => {
        const value = libCon(country);
        if (value) {
            if (value > 0) { return `${value}% (liberal)`; }
            if (value < 0) { return `${-1 * value}% (conservative)`; }
        }
        return null;
    }
    const libCon = (country) => {
        if (parties.find(x => x.country === country && !x.region)) {
            return libConScore(country);
        } else { return null; }
    }
    const seats = (country) => {
        return parties.find(x => x.country === country && !x.region)?.seats;
    }
    const euElectionMax = (country) => {
        const results = euPolitics.find(x => x.country === country && !x.region)?.euElections;
        for (const name in results) {
            if (name !== "year" && (parties.find(x => x.country === country)?.parties.find(x => x.party === name) ?? false)) {
                const value = results[name];
                return value;
            }
        }
    }

    const getDataExt = (country) =>
        parties.find(x => x.country === country && !x.region) ?
            (type === 0 
                ? `${dataPredict(country)} (${Math.round(Math.max(...getPrediction(country)))}%)`
                : type === 4 ? `${getData(country, false)} (${Math.round(Math.max(...groupByGroup(getChangeSinceElection(country), country)))}%)`
                : type === 6 ? `${getData(country)}%`
                : type === 7 ? `${getData(country)} days`
                : type < 10 ? getData(country) : `${getData(country)}%`)
                : '';
    const getData = (country, filterLow = true) => 
        type === 0 ? dataPredict(country) 
        : (type === 1 ? primeMinisterGroup(country)
        : (type === 2 ? leftRightText(country)
        : (type === 5 ? seats(country)
        : (type === 4 ? dataChange(country, filterLow)
        : (type === 6 ? dataGovPerc(country)
        : (type === 3 ? libConText(country)
        : (type === 7 ? pollAge(country)
        : predict(country))))))));
    const getColor = (country, overCountry, isDefault) => {
        if (type === 0 || type === 1 || type === 4) {
            return getColorByFunc(() => getData(country), country === overCountry);
        } else if (type === 2) {
            return getLeftRightColorByFunc(() => leftRight(country), country === overCountry);
        } else if (type === 5) {
            return getSeatsColorByFunc(() => seats(country), country === overCountry, isDefault);
        } else if (type === 6) {
            return getSeatsColorByFunc(() => Math.max(0, Math.min(100, 3 * dataGovPerc(country) - 60)), country === overCountry, isDefault);
        } else if (type === 3) {
            return getLibConColorByFunc(() => libCon(country), country === overCountry);
        } else if (type === 7) {
            const age = pollAge(country);
            return getSeatsColorByFunc(() => age ? 2*(50 - Math.pow(age, 0.4)*5) : age, country === overCountry, isDefault);
        } else {
            return getColorGroupScoreByFunc(() => predict(country), country === overCountry);
        }
    }
    const getColorByFunc = (getGroup, isSame) => {
        const group = getGroup();
        return group ? (isSame ? getLightColorByGroup(group) : getColorByGroup(group))
            : (isSame ? '#ccc' : 'transparent');
    }
    const getLeftRightColorByFunc = (getScore, isSame) => {
        const score = getScore();
        return score ? getColorByLeftRightScore(score)
            : (isSame ? '#ccc' : 'transparent');
    }
    const getLibConColorByFunc = (getScore, isSame) => {
        const score = getScore();
        return score ? getColorByLibConScore(score)
            : (isSame ? '#ccc' : 'transparent');
    }
    const getSeatsColorByFunc = (getScore, isSame, isDefault) => {
        const score = getScore();
        //if (!isDefault) { score = 100 - score; }
        return score ? getGrayColorByScore(score)
            : (isSame ? '#ccc' : (isDefault ? 'transparent' : '#333'));
    }
    const getColorGroupScoreByFunc = (getScore, isSame) => {
        const score = getScore();
        return score ? getColorByGroupScore(GROUPS[type-10], score)
            : (isSame ? '#ccc' : 'transparent');
    }

    return <div>
            <GeneralMap type={type} setType={setType} titles={titles} title={titles[type]} map={mapEurope}
                funcOverText={(country, cal) => <>{country}{cal ? ':' : ''} {cal}</>}
                funcGetColor={(country, overCountry, isDefault) => getColor(country, overCountry, isDefault)}
                funcCalculate={(country) => getDataExt(country)} />
            <div className="margin12">
                <span className="highlight pointer hoverable2 groupLeft" onClick={() => setType(10)}>Left</span>
                <span className="highlight pointer hoverable2 groupG" onClick={() => setType(11)}>Green</span>
                <span className="highlight pointer hoverable2 groupSD" onClick={() => setType(12)}>S&D</span>
                <span className="highlight pointer hoverable2 groupRe" onClick={() => setType(13)}>RE</span>
                <span className="highlight pointer hoverable2 groupEpp" onClick={() => setType(14)}>EPP</span>
                <span className="highlight pointer hoverable2 groupEcr" onClick={() => setType(15)}>ECR</span>
                <span className="highlight pointer hoverable2 groupId" onClick={() => setType(16)}>ID</span>
                <span className="highlight pointer hoverable2 groupNi" onClick={() => setType(17)}>NI</span>
            </div>
    </div>
}

export const CountryMap = (props) => {
    const { country } = props;
    const [type, setType] = useState(0);

    const primeMinisterGroup = (reg) => {
        const pol = euPolitics.find(x => x.country === reg);
        if (pol && pol.governments.length > 0 && pol.governments[0].parties && pol.governments[0].parties.length > 0) {
            const party = pol.governments[0].parties[0];
            const group = parties.find(x => x.country === country).parties.find(x => x.party === party)?.group;
            return group;
        }
        return null;
    }
    const partyData = (reg) => {
        const pol = euPolitics.find(x => x.country === reg);
        if (pol && pol.polls.length > 0) {
            const poll = pol.polls[0];
            const partiesCountry = parties.find(x => x.country === country);
            const partiesPoll = partiesCountry.parties.map(x => parseFloat(poll[x.party] ?? 0));
            return partiesPoll;
        }
        return null;
    }
    const biggestPartyData = (reg) => {
        const partiesPoll = partyData(reg);
        if (partiesPoll) {
            const maxIndex = getIndexOfMax(partiesPoll);
            const partiesCountry = parties.find(x => x.country === country);
            return { value: partiesPoll[maxIndex], group: partiesCountry.parties[maxIndex].group, party: partiesCountry.parties[maxIndex].party };
        }
        return { value: 0 };
    }
    const pollAge = (reg) => {
        const pol = euPolitics.find(x => x.country === reg);
        if (pol && pol.polls.length > 0) {
            const poll = pol.polls[0].dateJs;
            return Math.floor((new Date() - new Date(poll))/((1000 * 60 * 60 * 24)));
        }
        return null;
    }
    const populationData = (reg) => {
        const region = parties.find(x => x.region === reg);
        if (region) {
            return Math.floor(region.population / 100000)/10;
        }
        return null;
    }
    const getGroupScore = (reg) => {
        const scores = partyData(reg);
        const partiesCountry = parties.find(x => x.country === country);
        const score = scores ? partiesCountry.parties.map((x, i) => x.group === GROUPS[type-10] ? scores[i] : 0).reduce((s, a) => a + s, 0) : 0;
        return score;
    }
    const getDataExt = (reg) => {
        const result = type === 0 
            ? primeMinisterGroup(reg)
            : type === 1 ? biggestPartyData(reg)
            : type === 2 ? pollAge(reg) 
            : type === 3 ? populationData(reg)
            : getGroupScore(reg);
        return type === 0 ? result 
            : type === 1 ? (result.party ? `${result.party} (${result.group}) (${result.value})` : '')
            : type === 2 ? (result ? result + ' days' : '')
            : type === 3 ? (result ? result + ' millions' : '')
            : (result === 0 ? '' : result);
    }
    
    const getData = (reg) => 
        type === 0 ? primeMinisterGroup(reg)
            : type === 1 ? biggestPartyData(reg).group
            : type === 2 ? pollAge(reg)
            : populationData(reg);
    const getColor = (reg, isSame) => {
        if (type >= 10) {
            const score = getGroupScore(reg);
            return score ? getColorByGroupScore(GROUPS[type-10], score)
            : (isSame ? '#ccc' : 'transparent');
        } else {
            const group = getData(reg);
            return group 
                ? type === 2 ? getGrayColorByScore(100 - group)
                : type === 3 ? getGrayColorByScore(10 * group) // 10 stand for 10 million
                : (isSame ? getLightColorByGroup(group) : getColorByGroup(group))
                : (isSame ? '#ccc' : 'transparent');
        }
    }
    
    const titles = ["regional government", "", "", "", "", "", "", "", "", "", "Left", "Green", "S&D", "RE", "EPP", "ECR", "ID", "NI"];
    switch (country) {
        case "Spain": break;
        case "Germany": titles[1] = "polls - biggest party"; titles[2] = "latest poll"; titles[3] = "population"; break;
        case "Italy": break;
        default: break;
    }

    return <><GeneralMap country={country} titles={titles} type={type} setType={setType} title={titles[type]}
        funcOverText={(reg, cal) => <>{reg}{cal ? ':' : ''} {cal}</>} 
        funcGetColor={(reg, overReg, isDefault) => getColor(reg, reg === overReg, isDefault)} 
        funcCalculate={(reg) => getDataExt(reg)} />
        { country === "Germany" &&
        <div className="margin12">
            <span className="highlight pointer hoverable2 groupLeft" onClick={() => setType(10)}>Left</span>
            <span className="highlight pointer hoverable2 groupG" onClick={() => setType(11)}>Green</span>
            <span className="highlight pointer hoverable2 groupSD" onClick={() => setType(12)}>S&D</span>
            <span className="highlight pointer hoverable2 groupRe" onClick={() => setType(13)}>RE</span>
            <span className="highlight pointer hoverable2 groupEpp" onClick={() => setType(14)}>EPP</span>
            <span className="highlight pointer hoverable2 groupEcr" onClick={() => setType(15)}>ECR</span>
            <span className="highlight pointer hoverable2 groupId" onClick={() => setType(16)}>ID</span>
            <span className="highlight pointer hoverable2 groupNi" onClick={() => setType(17)}>NI</span>
        </div>
        }
        </>
}

export const GeneralMap = (props) => {
    const { type, setType, titles, title, funcOverText, funcGetColor, funcCalculate, country } = props;
    const [overCountry, setOverCountry] = useState('');
    const calculated = funcCalculate(overCountry);
    const navigate = useNavigate();
    const titleNew = country + " - " + title;
    let map;
    let move = 0;
    let moveY = 0;
    let aspect = 1;
    let onClick = () => {};
    switch (country) {
        case "Spain": map = mapSpain; move = 80; aspect = 1; break;
        case "Germany": map = mapGermany; move = 160; aspect = 0.62; onClick=() => { navigate("/region/"+overCountry); }; break;
        case "Italy": map = mapItaly; move = 0; aspect = 1; moveY = 30; break;
        default: map = mapEurope; move = 0; aspect = 1; onClick=() => { 
            const short = getShortCountry(overCountry);
            if (short){ navigate("/" + short); }
        }; 
        break;
    }
    const getChildren = (isDefault) => {
        return (<div className="margin8">
            <img className={"absolute floatLeft" + (isDefault ? " collapsed" : "")} src="Statostuff.png" alt="Statostuff" />
            <div>
                <span className={"underline " + (isDefault ? "light" : "collapsed")}>{title ?? titleNew}</span>
                <div className="mapTable">
                    { titles && titles.filter((x, i) => i < 10 && x !== "").length > 1 &&
                        <div className="mapMenuPart">
                            { titles.filter((x, i) => x && i < 10).map((x, i) => {
                                    return <div key={i} className={"underline pointer " + (isDefault ? "light" : "dark")} onClick={() => setType(i)}><span className={"dot " + (type === i ? "groupG" : "groupNi")} />&nbsp;{x}</div>
                                })
                            }
                        </div>
                    }

                    <div className="absolute margin12"><b>{funcOverText(overCountry, calculated)}</b></div>
                    <div className="margin12 mapPart">
                        <svg width="700" height="500" viewBox="0 0 600 500">
                            <g stroke="black" fill="transparent" transform={`translate(${move}, ${moveY}) matrix(${aspect},0,0,${aspect},0,0)`}>
                                {
                                    map.map((x, i) => { 
                                        return <g key={i} className="pointer" fill={x.name === "x" ? "red" : funcGetColor(x.name, overCountry, isDefault)}
                                            opacity={overCountry === x.name ? 0.8 : 1} 
                                            onClick={() => onClick()}
                                            onMouseLeave={() => setOverCountry('')}
                                            onMouseOver={() => setOverCountry(x.name)}>
                                                {x.paths.map((y, j) => <path key={j} d={y} />)}
                                        </g> 
                                    })
                                }
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div>)
    }

    return <Picturable getChildren={getChildren} downloadName={`map_${country ?? 'EU'}_${type ?? 'default'}.png`} />
}