import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

export const StackBar2Graph = (props) => {
    const { title, labels, dataAll } = props;

    const plugin = {
        id: 'background',
        beforeDraw: (chart, args, opts) => {
          if (!opts.color) {
            return;
          }
      
          const {
            ctx,
            chartArea
          } = chart;
      
          ctx.fillStyle = opts.color;
          ctx.fillRect(chartArea.left, chartArea.top, chartArea.width, chartArea.height)
        }
      }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        plugin,
        ChartDataLabels
      );
      
      const options = {
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: { 
            display: false
          },
          background: { color: '#333' },

          datalabels: {
            display: false,
            color: "#fff",
            formatter: Math.round,
            anchor: "end",
            offset: 2,
            align: "start"
          }
        },
        responsive: true,
        /*interaction: {
          mode: 'index',
          intersect: false,
        },*/
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
        datasets: {
            bar: {
                barPercentage: 1.4,
                categoryPercentage: 0.60
            }
        }
      };
      
      const getColor = (label) => {
        switch (label) {
          case 'left': return '#a00';
          case 'g': return '#4a4';
          case 's&d': return '#f00';
          case 're': return '#bb0';
          case 'epp': return '#3af';
          case 'ecr': return '#00f';
          case 'id': return '#009';
          case 'ni': return '#999';
          default: return '#fff';
        }
      }
      const data = {
        labels,
        datasets: 
          dataAll.map(x => ({
            label: x.label,
            data: x.data,
            backgroundColor: ['left', 'g', 's&d', 're', 'epp', 'ecr', 'id'].map(x => getColor(x)),
          }))
      };

    return (
        <div>
            <Bar options={options} data={data} />
        </div>
    )
}