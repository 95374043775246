import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const updateCookie = (short, pollField) => {
    const data = cookies.get("latest") ?? [];
    const toUpdate = data.find(x => x.country === short);
    if (toUpdate) {
        toUpdate.poll = pollField;
    } else {
        data.push({country: short, poll: pollField});
    }

    cookies.set("latest", data, { path: '/' });
}
export const getCookieCount = (short, polls) => {
    const data = cookies.get("latest") ?? [];
    //console.log('cookies', data);
    const found = data.find(x => x.country === short);
    if (found && found !== null && polls) {
        for (let i = 0; i < polls.length; ++i) {
            if (polls[i].field === found.poll) {
                return i >= 10 ? 0 : i;
            }
        }

    }
    return 0;
}

export const getAndSetFirstDayOfMonth = (label, value) => {
    const result = cookies.get(label);
    const d = new Date();
    if (d.getDate() === 1) {
        cookies.set(label, value, { path: '/' });
    }
    return result;
}