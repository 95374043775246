import { getCountryData, getPartiesFunc, groupByGroup, partyFiltered, politicData } from "../calculations/calc";
import { getAllCombinationsOfCount, getColorByLikelihood, getIndexOfMax, getTextByLikelihood, styleByGroup } from "../calculations/utils";
import { useData } from "../context/DataContext";

export const QuestionBoard = (props) => {
    const { country } = props;
    const { getPredictionSeat, getPredictionEuSeat, getPrediction } = useData();

    const CONFIDENCE = 2;

    const getYesNo = (cond) => cond ? { group: 'G', party: 'yes' } : { group: 'S&D', party: 'no' };
    const getLessMore = (cond) => cond ? { group: 'G', party: 'less' } : { group: 'S&D', party: 'more' };
    const getLikelihood = (a, b) => 12 * (a-b)*(a-b) + 40;
    // Probability that one probabilistic number is the biggest
    const getMaxLikelihood = (arr) => {
        var max = Math.max(...arr);
        return 90 / (arr.reduce((s, a) => s + Math.max(0, (a - max + CONFIDENCE*2)/(CONFIDENCE*2)), 0));
    }
    // Probability that specific amount of probabilistic numbers are over a limit
    const getLikelihoodXPassesThreshold = () => {
        const probabilities = prediction.filter(x => Math.abs(x - threshold) < CONFIDENCE).map((a) => (a - threshold)/CONFIDENCE, 0)
            .map(a => (a < 0 ? 1 : 0) + Math.sign(a) * 1 / (2 - Math.abs(a)));
        let result = 0;
        const thresholdValues = prediction.filter(x => Math.abs(x - threshold) < CONFIDENCE);
        const combinations = getAllCombinationsOfCount(thresholdValues.length, thresholdValues.reduce((s, a) => s + (a > threshold ? 1 : 0), 0));
        for (var item of combinations){
            let onePossiblity = 1;
            for (var i = 0; i < probabilities.length; ++i) {
                onePossiblity *= item.includes(i) ? probabilities[i] : 1 - probabilities[i];
            }
            result += onePossiblity;
        }
        return 100 * result;
    }

    const prediction = getPrediction(country);
    const predictionSeats = getPredictionSeat(country);
    const countryParties = getPartiesFunc(country);
    const parties = partyFiltered(country);
    const threshold = parties.threshold;
    const countryData = getCountryData(country);
    const govParties = countryData.governments.length > 0 ? countryData.governments[0].parties : [];
    const govMajority = predictionSeats.reduce((s, a, i) => s + (govParties.includes(countryParties[i].party)? predictionSeats[i] : 0), 0);
    const governmentMajorityYn = getYesNo(govMajority >= parties.parliament/2 + 1);
    const pmParty = countryParties.find(x => x.party === govParties[0]);
    const pmPartyPrev = countryData.elections[0][pmParty.party];
    const pmPartyPred = countryParties.reduce((s, a, i) => s + (a.party === pmParty.party ? prediction[i] : 0), 0);

    return (<div>
        <span className="underline">election predictions</span>
        <div className="bigMargin">
            <Question text="Which party will be the biggest?" answer={countryParties[getIndexOfMax(prediction)]} likelihood={getMaxLikelihood(prediction)} />
            <Question text="How many parties will get into the parliament?" answer={prediction.reduce((s, a) => s + (a > threshold ? 1 : 0), 0)} likelihood={getLikelihoodXPassesThreshold()} />
            <Question text="Will the current government gain majority?" answer={governmentMajorityYn} likelihood={getLikelihood((govMajority - parties.parliament/2 + 1)/(CONFIDENCE * parties.parliament / 80), 0)} />
            <Question answer={getLessMore(pmPartyPrev - 5 < pmPartyPred)} likelihood={getLikelihood(pmPartyPred, pmPartyPrev-5)}>
                Will <span className={"highlight " + styleByGroup(pmParty?.group)}>{pmParty?.party}</span> lose more or less than 5%?
            </Question>
        </div>
    </div>)
}

export const Question = (props) => {
    const { text, answer, likelihood, children } = props;

    return (<div className="margin8">
        {text ?? children} <span className={"highlight " + styleByGroup(answer?.group)}>{answer?.party ?? answer}</span> 
        <span className={"highlight"} style={{backgroundColor: getColorByLikelihood(likelihood)}}>{getTextByLikelihood(likelihood)}</span>
    </div>)
}