import { createContext, useContext, useReducer } from "react";
import { getPredictionSeats, getPredictionSimple, getSeats } from "../calculations/calc";

export const DataContext = createContext(null);

export function useData(){
    return useContext(DataContext);
}

export const GET_PREDICTION_SEATS = 'GET_PREDICTION_SEATS';
export function DataProvider(props){
    const data =
        { 
            country: '',
            perCountry: [{
                country: '',
                prediction: null,
                predictionSeats: null,
                predictionEuSeats: null
            }]
        };
    const getPerCountry = (country) => { 
        const res = data.perCountry.find(x => x.country === country);
        if (!res){
            data.perCountry.push({ country });
        }
        return res ?? data.perCountry.find(x => x.country === country);
    }


    const getPrediction = (country) => {
        const oldData = getPerCountry(country);
        if (!oldData.prediction ?? true) {
            const prediction = getPredictionSimple(country, true);
            oldData.prediction = prediction;
            return prediction;
        }
        return oldData.prediction;
    }
    const getPredictionSeat = (country) => {
        const oldData = getPerCountry(country);
        if (!oldData.predictionSeats ?? true){
            const prediction = getPrediction(country);
            const seats = getSeats(prediction, country, true);
            oldData.predictionSeats = seats;
            return seats;
        }
        return oldData.predictionSeats;
    }
    const getPredictionEuSeat = (country) => {
        const oldData = getPerCountry(country);
        if (!oldData.predictionEuSeats ?? true){
            const prediction = getPrediction(country);
            const seats = getSeats(prediction, country, false);
            oldData.predictionEuSeats = seats;
            return seats;
        }
        return oldData.predictionEuSeats;
    }

    return (
        <DataContext.Provider value={{data, getPredictionSeat, getPredictionEuSeat, getPrediction}}>
            {props.children}
        </DataContext.Provider>
    )
}