import { Link } from "react-router-dom";
import { styleByGroup } from "./utils"
import parties from '../data/parties.json';


const getSum = (groups, seats, groupList) => {
    return groupList.map((x, i) => groups.includes(x) ? seats[i] : 0).reduce((a, b) => a + b, 0);
}
export const getSumByParty = (parties, seats, partyList) => {
    return partyList.map((x, i) => parties.includes(x) ? seats[i] : 0).reduce((a, b) => a + b, 0);
}
const getCoalition = (groups, name, groupStyle, parliamentSize, seats, groupList) => {
    const sum = getSum(groups, seats, groupList);
    return <div className="margin12">
        {groups.map(x => <span key={x} className={"highlight " + styleByGroup(x)}>{x}</span>)}
        -
        <span className={"highlight " + styleByGroup(groupStyle)}>{name}: {sum}</span>&nbsp;
        {(sum >= 1 + Math.floor(parliamentSize/2)) ? <span className="dot groupG" /> : <span className="dot groupSD" />}
    </div>
}
export const getCoalitions = (parliamentSize, govs, seats, groupList, partyList) => {
    return <div>
        <div className="margin12">
            <span className="highlight groupNi">all seats: {parliamentSize}</span>&nbsp;
            <span className="highlight groupNi">majority: {Math.round((parliamentSize+1)/2)}</span>&nbsp;
            <span className="highlight groupNi">government&nbsp;
                {(partyList ? getSumByParty(govs.map(x => x.party), seats, partyList) : getSum(govs.map(x => x.group ? x.group : x), seats, groupList)) 
                    >= 1 + parliamentSize/2 ? <span className="dot groupG" /> : <span className="dot groupSD" />}
            </span>
            &nbsp;{govs.map((x, i) => <span key={i} className={"highlight " + styleByGroup(x.group ? x.group : x)}>{x.group ? x.group : x}</span>)}
        </div>
        {[{ a: ['Left', 'G', 'S&D'], b: 'left', c: 'Left' },
            { a: ['G', 'S&D', 'RE'], b: 'center-left', c: 'S&D' },
            { a: ['S&D', 'RE', 'EPP'], b: 'center-left/right', c: 'RE' },
            { a: ['RE', 'EPP', 'ECR'], b: 'center-right', c: 'EPP' },
            { a: ['EPP', 'ECR', 'ID'], b: 'right', c: 'ID' },
        ].map(x => <div key={x.b}>{getCoalition(x.a, x.b, x.c, parliamentSize, seats, groupList)}</div>)}
    </div>
}

export const getLeftRightScore = (seats, groupList) => {
    const left = getSum(["Left", "G", "S&D"], seats, groupList);
    const right = getSum(["EPP", "ECR", "ID"], seats, groupList);

    return <span>
        <span className="highlight groupLeft">left:&nbsp;{left}</span>
        <span className="highlight groupId">right:&nbsp;{right}</span>
    </span>
}

export const countryDiv = (country) => {
    const found = parties.find(x => x.country === country) ?? parties.find(x => x.region === country);
    const short = found?.short
    if (!short) {
        const flagShort = parties.find(x => x.country === found?.country)?.short ?? country;
        return <span className="highlight hlFirst">
            <img src={"flags/" + flagShort.toLowerCase() + ".png"} alt={found?.region ?? country} height={14} />&nbsp;
            {found?.region ?? country}
        </span>
    }

    return <Link to={"/"+short}><span className="highlight hlFirst">
                <img src={"flags/" + short.toLowerCase() + ".png"} alt={found.country} height={14} />&nbsp;
                {found.country}
            </span>
        </Link>
}

export const ratingDiv = (value, min, max) => {
    const arr = Array(Math.max(1, Math.round(5 - 4 * (value - min)/(max - min)))).fill(0);
    return <span className="highlight groupNi">
        {arr.map((x, i) => <img key={i} src="/star.png" alt="star" height={12} />)}
    </span>
}

export const getClass = (group) => {
    switch (group) {
        case "Left": return "groupLeft";
        case "G": return "groupG";
        case "S&D": return "groupSD";
        case "RE": return "groupRe";
        case "EPP": return "groupEpp";
        case "ECR": return "groupEcr";
        case "ID": return "groupId";
        case "NI": return "groupNi";
        default: return "";
    }
}
