import { useState } from "react";
import { CountriesHeader } from "./CountriesHeader";

export const CountriesPage = () => {
    const [selectedCountry, setSelectedCountry] = useState('EU');
    
    return (
        <div>
            <CountriesHeader selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
            
        </div>
    );
}