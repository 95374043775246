import { createRef } from "react";
import { useScreenshot } from "../use-react-screenshot/src";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { BarGraph } from "../components/BarGraph";
import { SemiCircleGraph } from "../components/SemiCircleGraph";

export const Picturable = (props) => {
    const { downloadName, createText, getMenu, children, getChildren } = props;
    const ref = createRef(null);
    const [image, takeScreenshot] = useScreenshot();
    const [isDefault, setIsDefault] = useStateWithCallbackLazy(true);
    
    const download = () => {
        if (image) {
            var element = document.createElement("a");
            element.href = image;
            element.download = downloadName;
            element.click();
            setIsDefault(x => true);
        }
      };
    const takeScreen = () => {
        const r = ref.current;
        setIsDefault(false, () => {
            setTimeout(() => {
                takeScreenshot(r);
                download();
            }, 10);
        });
        if (createText) {
            console.log(createText());
        }
    }

    
    return (<div>
        <span className={"floatRight pointer" + (isDefault ? "" : " collapsed")}>
            { getMenu && getMenu() }
            <img className="hoverable" width={24} src='/camera.png' alt='take a picture' onClick={() => takeScreen()} />
        </span>
        <div ref={ref}>
            {children}
            {getChildren && getChildren(isDefault)}
        </div>
        <div className="collapsed">
            <img className="collapsed" src={image} alt='' onLoad={() => download()} />
        </div>
    </div>)
}

export const PicturableGraph = (props) => {
    const { downloadName, createText, title, children, labels, data, useBarGraph, useSemiCircleGraph } = props;
    const ref = createRef(null);
    const [image, takeScreenshot] = useScreenshot();
    const [isDefault, setIsDefault] = useStateWithCallbackLazy(true);
    
    const download = () => {
        if (image) {
            var element = document.createElement("a");
            element.href = image;
            element.download = downloadName;
            element.click();
            setIsDefault(x => true);
        }
      };
    const takeScreen = () => {
        const r = ref.current;
        setIsDefault(false, () => {
            setTimeout(() => {
                takeScreenshot(r);
                download();
            }, 10);
        });
        if (createText) {
            console.log(createText());
        }
    }

    
    return (<div>
        <span className={"floatRight pointer" + (isDefault ? "" : " collapsed")}>
            <a href={encodeURI("https://twitter.com/intent/tweet?text=https://statostuff.com")} target="twitter">
                <img className="hoverable" width={24} src='/twitterLogo2.png' alt='tweet' />
            </a>
            <img className="hoverable" width={24} src='/camera.png' alt='take a picture' onClick={() => takeScreen()} />
        </span>
        <div ref={ref}>
            <img className={"floatLeft " + (isDefault ? " collapsed" : "")} src="/Statostuff.png" alt="Statostuff" />
            {children}
            { useBarGraph && 
                <BarGraph bgColor={isDefault ? '#333' : '#fff'} title={title} labels={labels} dataNow={data} />
            }
            { useSemiCircleGraph && 
                <SemiCircleGraph bgColor={isDefault ? '#333' : '#fff'} title={title} labels={labels} dataNow={data} height={200} />
            }
        </div>
        <div className="collapsed">
            <img className="collapsed" src={image} alt='' onLoad={() => download()} />
        </div>
    </div>)
}