import { Link } from "react-router-dom";
import { DHondt, getChangeSinceElection, getPartiesFunc, getPrediction, getPredictionSeats, getPredictionSimple, govPerc } from "../calculations/calc";
import { getCoalitions, getLeftRightScore } from "../calculations/calculationDivs";
import { space, styleByGroup } from "../calculations/utils";
import { BarGraph } from "../components/BarGraph";
import euPolitics from '../data/euPolitics5.json';
import parties from '../data/parties.json';
import nextElection from '../data/nextElection.json';
import { CountryMap } from "./EuropeMap";
import { useContext, useEffect, useRef, useState } from "react";
import { DataContext, useData } from "../context/DataContext";
import { QuestionBoard } from "./QuestionBord";

export const PredictionCountry = (props) => {
    const { country, isParliament } = props;
    const { getPredictionSeat, getPredictionEuSeat, getPrediction } = useData();

    //const lastElectionDate = nextElection.findLast(x => x.country === country).date;
    const euFiltered = euPolitics.filter(x => x.country === country)[0];
    const polls = euFiltered.polls;//.filter((x, i) => i < 10 || lastElectionDate === null || new Date(x.dateJs) > new Date(lastElectionDate));
    const govParties = euFiltered.governments.length > 0 ? euFiltered.governments[0].parties : [];
    const lastElection = euFiltered.elections[0];
    const partyFiltered = parties.find(x => x.country === country) ?? parties.find(x => x.region === country);
    const parliament = isParliament ? partyFiltered.parliament
        : partyFiltered.seats;
    const countryParties = getPartiesFunc(country); //partyFiltered.parties.filter(x => !(x.filtered ?? false));
    const short = partyFiltered.short;
    const govs = countryParties.filter(x => govParties?.includes(x.party));
    if (polls.count === 0) { return undefined; }

    const lastElectionData = countryParties.map(x => lastElection[x.party] ?? 0);
    //const seats = getPredictionSeats(country, isParliament);
    const graphTitle = country + ' election prediction';
    const seats = isParliament ? getPredictionSeat(country) : getPredictionEuSeat(country);
    const data = isParliament ? getPrediction(country) : seats; //isParliament ? getPredictionSimple(country) : seats;

    return <div>
        <span className="underline">
            {!isParliament && 
                <Link to={"/" + short}><span className="highlight groupNi"><img src={"flags/" + short.toLowerCase() + ".png"} alt={country} height={12} /> {short}</span>&nbsp;</Link>
            }
            seats
        </span>
        <div className="margin12">
            {countryParties.filter((x, i) => seats[i] > 0).map((x, i) => <span key={x.party} className={"highlight " + styleByGroup(x.group)}>{x.group}: {seats.filter(x => x > 0)[i]}</span>)}
            &nbsp;|&#32;{getLeftRightScore(seats, countryParties.map(x => x.group))}&nbsp;|&#32;<span className="highlight groupNi">last&nbsp;update:&nbsp;{new Date(polls[0].dateJs).toLocaleDateString('de')}</span>
        </div>

        {isParliament &&
        <>
            <span className="underline">coalitions</span>
            {getCoalitions(parliament, govs, seats, countryParties.map(x => x.group), countryParties.map(x => x.party))}
        </>
        }

        {isParliament && 
        <>
            <span className="underline">last election ({lastElection["year"]})</span>
            <div className="margin12">
                {countryParties.map((x, i) => <span key={x.party}><span className={"highlight " + styleByGroup(x.group)}>{x.group}:&nbsp;{Math.round(lastElectionData[i]*10)/10}%</span>{space}</span>)}
            </div>
            <span className="underline">change since last election</span>
            <div className="margin12">
                {getChangeSinceElection(country).map((x, i) => <span key={countryParties[i].party}><span className={"highlight " + styleByGroup(countryParties[i].group)}>{countryParties[i].group}:&nbsp;{x > 0 ? '+' : ''}{x}%</span>{space}</span>)}
            </div>
        </>
        }

        <BarGraph title={graphTitle} labels={countryParties.map(x => x.party + '\n(' + x.group + ')')} dataNow={data} />
        {(["Spain", "Germany", "Italy"].includes(country)) && isParliament &&
            <CountryMap country={country} />
        }
    </div>
}