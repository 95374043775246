import { React } from 'react';
import { getPartiesFunc, getShort, lastElectionDateFunc, pollsFunc } from '../calculations/calc';
import { BarGraph } from '../components/BarGraph';
import { LineGraph } from '../components/LineGraph';
import euPolitics from '../data/euPolitics5.json';
import parties from '../data/parties.json';
import { updateCookie } from '../items/CookieData';
import { GraphHeader } from '../items/GraphHeader';
import { PredictionCountry } from '../items/PredictionCountry';
import { useScreenshot } from '../use-react-screenshot/src/index';
import { createRef } from 'react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { Link } from 'react-router-dom';
import { Picturable, PicturableGraph } from '../items/Picturable';
import { QuestionBoard } from '../items/QuestionBord';

export const CountryPage = (props) => {
    const { country, short } = props;
    const polls = pollsFunc(country);

    if (polls.length === 0) { return <div /> }

    var countryParties = getPartiesFunc(country); //parties.filter(x => x.country === country)[0].parties.filter(x => !(x.filtered ?? false));
    updateCookie(short, polls[0].field);

    const revPolls = polls.filter(x => new Date(x.dateJs) > new Date().setFullYear(new Date().getFullYear() - 4)).map(x => ({ month: new Date(x.dateJs).getMonth(), ...x })).reverse();
    //console.log('polls:', revPolls);

    const dataLinesTemp = [...new Set(revPolls.map(x => x.year + "|" + x.month))]
        .map(x => { 
            const fil = revPolls.filter(y => y.year + "|" + y.month === x); 
            return fil.map(z => countryParties.map(c => /^([0-9.]+)$/.test(z[c.party]) ? z[c.party] : 0))
                .reduce((s, a) => s.map((sa, si) => sa + a[si]), countryParties.map(c => 0))
                .map(f => Math.round(10 * f / fil.length)/10);
        });
    const dataLines = dataLinesTemp.reduce((prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])), []);
    const legends = [...new Set(revPolls.map(x => x.year + "-" + (x.month+1)))].map(x => new Date(`${x}-01`).toLocaleDateString());

    const euFiltered = euPolitics.filter(x => x.country === country)[0];
    const lastElection = euFiltered.elections[0];
    const lastElectionSeats = euFiltered.electionsSeats[0];
    const labels = countryParties.filter(x => lastElection[x.party]).map(x => x.party + "\n(" + x.group + ")");
    const data = countryParties.map(x => lastElection[x.party]).filter(x => x);
    const labelsSeats = countryParties.filter(x => lastElectionSeats[x.party]).map(x => x.party + "\n(" + x.group + ")");
    const dataSeats = countryParties.map(x => lastElectionSeats[x.party]).filter(x => x);

    return (
        <div className="countryDiv">
            <ElectionResultLink country={country} short={short} />
            <LineGraph labels={legends}
                title={country + ' (' + polls[polls.length - 1].year + ' - ' + (polls[0].year ? polls[0].year : '') + ')'}
                parties={countryParties.map(x => x.party + '\n(' + x.group + ')')}
                dataItems={dataLines} />
            
            <PredictionCountry country={country} isParliament={true} />
            <div className="parentOfHalf">
                <div className="half">
                    <PicturableGraph downloadName="electionResult.jpg" title={country + " election result " + lastElection.year} labels={labels} 
                        data={data} useSemiCircleGraph={true} />
                    { euFiltered.elections.length > 1 && 
                        <>
                            <span className="highlight hlFirst">{euFiltered.elections[0].year} - {euFiltered.elections[1].year}</span>
                            <GraphHeader curr={euFiltered.elections[0]} prev={euFiltered.elections[1]} parties={countryParties} noThreshold={true} />
                        </>
                    }
                </div>
                <div className="half">
                    <PicturableGraph downloadName="electionResultSeats.jpg" title={country + " election result seats " + lastElection.year} labels={labelsSeats} 
                        data={dataSeats} useSemiCircleGraph={true} />
                    { euFiltered.electionsSeats.length > 1 && 
                        <>
                            <span className="highlight hlFirst">{euFiltered.electionsSeats[0].year} - {euFiltered.electionsSeats[1].year}</span>
                            <GraphHeader curr={euFiltered.electionsSeats[0]} prev={euFiltered.electionsSeats[1]} parties={countryParties} noThreshold={true} />
                        </>
                    }
                </div>
            </div>
            <br />
            <QuestionBoard country={country} />
            <span className="underline">election polls</span>
            <br /><br />
            {polls.slice(0, 10).map((x, i) => {
                if (polls.slice(0, i).map(y => y.firm).includes(x.firm)) { return null; }
                const prev = polls.slice(i + 1).find(y => y.firm === x.firm);
                return <div key={i}>
                    <GraphHeader curr={x} prev={prev} parties={countryParties} />
                    
                    <PollItem country={country} countryParties={countryParties} polls={polls} item={x} i={i} />
                    <hr />
                </div>
            })}
        </div>
    );
}

const ElectionResultLink = (props) => {
    const { country, short } = props;
    if (!short){ return null; }

    const isSameDay = (d1, d2) => {
        return d1.getFullYear() === d2.getFullYear() &&
          d1.getDate() === d2.getDate() &&
          d1.getMonth() === d2.getMonth();
    }
    const lastElectionDate = lastElectionDateFunc(country)

    return lastElectionDate && isSameDay(new Date(lastElectionDate), new Date()) ? (
        <div>
            <br />
            <Link to={"/"+short+"/result"}>
                <div className="highlight groupNi">
                    <span className="dot groupSD" />
                    &nbsp;Live election data...
                </div>
            </Link>
        </div>
    ) : null;
}

const PollItem = (props) => {
    const { country, countryParties, polls, item, i } = props;

    const election = euPolitics.filter(x => x.country === country)[0].elections[0];
    const getItem = (x, i) => 
    {
        return countryParties.map(y => y.party).map(y => 
        { 
            const item = polls.slice(i + 1).find(z => z.firm === x.firm);
            return item ? item[y] : 0;
        });
    }
    const getTitle = (x) => country + " / " + x.firm + " / " + x.field + " " + (x.year ? x.year : "");
    const createText = () => {
        const prev = getItem(item, i);
        const diff = prev ? countryParties.map((y, j) => Math.round(10 * (-prev[j] + item[y.party]))/10) : null;
        const texts = countryParties.map((y, j) => `${y.party} (${y.group}): ${item[y.party]}%`
            + (diff && (diff[j] >= 0.5 || diff[j] <= -0.5) ? ` (${diff[j] > 0 ? '+' : ''}${diff[j]})` : ""));
        const res = texts.reduce((s, a) => s + "\n" + a, country + ", " + item.firm + ":\n");
        return res + '\n\n' + item.field + '\nhttps://statostuff.com/' + getShort(country);
    }

    const getMenu = () => {
        return (<a href={encodeURI("https://twitter.com/intent/tweet?text=https://statostuff.com/" + getShort(country))} target="twitter">
            <img className="hoverable" width={24} src='/twitterLogo2.png' alt='tweet' />
        </a>);
    }
    const getChildren = (isDefault) => {
        return (<>
            <img className={"floatLeft " + (isDefault ? " collapsed" : "")} src="Statostuff.png" alt="Statostuff" />
            <img className={"floatRight" + (isDefault ? " collapsed" : "")} src="mini.png" alt="legend" />
            <BarGraph bgColor={isDefault ? "#333" : "#fff"} title={getTitle(item)} 
                labels={countryParties.map(x => x.party + '\n(' + x.group + ")")}
                dataNow={countryParties.map(y => y.party).map(y => item[y])}
                dataBefore={getItem(item, i)}
                election={countryParties.map(y => y.party).map(y => election[y] ? election[y] : 0)} />
        </>);
    }

    return <Picturable getMenu={getMenu} downloadName={`poll_${country}_${item.field}_${item.firm}.png`} createText={createText} getChildren={getChildren} />
}