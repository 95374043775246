
import parties from '../data/parties.json';
import { BrowserRouter as Router, Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { CountryPage } from "./CountryPage";
import { useEffect, useState } from "react";
import { PredictionEu } from "../items/PredictionEu";
import { AllPage } from "./AllPage";
import { ResultPage } from './ResultPage';
import { EuResultPage } from './EuResultPage';

export const CountriesHeader = () => {
    const countryList = parties.map(x => x.short).filter(x => !["UK", "CY"].includes(x));
    countryList.splice(0, 0, "EU");
    countryList.splice(0, 0, "All");
    const regionList = parties.map(x => x.region).filter(x => x);

    return (
        <div className="wrap">
            {/*<Router>*/}
                <Routes>
                    {countryList.map(x => <Route key={x} path={x} element={<CountryElement short={x} />} />)}
                    {countryList.map(x => <Route key={x+'_result'} path={x+'/result/'} element={<CountryElement short={x+'/result/'} />} />)}
                    <Route path="/" element={<CountryElement short="All" />} />
                    {regionList.map(x => <Route key={x} path={"/region/"+x} element={<CountryElement short={'/region/'+x} />} />)}
                    <Route path="EU-result" element={<CountryElement short="EU-result" />} />
                </Routes>
            {/*</Router>*/}
        </div>
    )
}

const CountryElement = (props) => {
    const { short, setSelectedCountry } = props;
    let navigate = useNavigate();

    const convertName = (short) => { 
        return parties.find(x => x.short === short)?.country ?? short;
    }

    useEffect(() => {        
        window.addEventListener("message", ev => {
            if (parties.map(x => x.short).includes(ev.data) || ["All", "EU"].includes(ev.data)) {
                navigate("/" + ev.data);
            }
        });
    }, [short, setSelectedCountry, navigate]);

    return (
        <div className="all">
            {short !== "EU" && short !== "All" && short !== "EU-result" && !short.includes("/result") && !short.includes("/region") &&
                <CountryPage country={convertName(short)} short={short} />
            }
            {short === "EU" &&
                <PredictionEu />
            }
            {short === "All" &&
                <AllPage />
            }
            {short.includes("/result") &&
                <ResultPage country={convertName(short.substring(0, short.indexOf('/result')))} />
            }
            {short === "EU-result" &&
                <EuResultPage />
            }
            {short.includes("/region") &&
                <CountryPage country={convertName(short.substring(short.indexOf('/region/')+'/region/'.length))} />
            }
        </div>
    )
}