import { createRef, useState } from "react";
import { getPredictionSeats, getPredictionSimple, getSeats, getShort, pollQuality, pollsFunc } from "../calculations/calc";
import { ratingDiv } from "../calculations/calculationDivs";
import { countryPartiesFunc, politicsFunc } from "../calculations/data";
import { space, styleByGroup } from "../calculations/utils";
import { BarGraph } from "../components/BarGraph";
import { SemiCircleGraph } from "../components/SemiCircleGraph";
import { Picturable } from "../items/Picturable";

export const ResultPage = (props) => {
    const { country } = props;
    const getTitle = () => "Prediction for " + country;
    const countryParties = countryPartiesFunc(country);
    const politics = politicsFunc(country);
    const polls = pollsFunc(country);
    //const poll = polls[1];
    const counted = politics.elections[0].counted ?? 0;
    const lastElectionData = politics.elections[0].counted ? politics.elections[1] : politics.elections[0];
    const currentResult = politics.elections[0].counted ? countryParties.map(x => politics.elections[0][x.party]) : null;
    //const pollData = countryParties.map(y => y.party).map(y => poll[y] ?? 0);
    
    // Adjustment for vote counting based on historical elections
    const adjRate = country === "Spain" ? [1/2, 14.4/14, 3.9/4, 28.7/30, 1.5/3, 1.9/1.7, 16.7/17, 10.3/10] : [];
    const isParliament = true;
    const simplePrediction = getPredictionSimple(country, true);
    const data = simplePrediction.map((x, i) => Math.round(10 * (x * (100 - counted) + counted * ((adjRate[i] ?? 0) * (100 - counted)/100) * (currentResult ? currentResult[i] : 0))/100)/10);
    const seatsData = getSeats(data, country, isParliament);
    
    const createText = (index) => {
        return 'Current Statostuff prediction for election in ' + country + ':\n\n' + 
            (index === 0 ?
                countryParties.map((x, i) => x.party + ': ' + Math.round(10 * data[i])/10 + '%').reduce((s, a) => s + a + '\n', '')
                : countryParties.map((x, i) => x.party + ': ' + seatsData[i]).reduce((s, a) => s + a + '\n', '') + '\n'
                    + (Math.round(1 + seatsData.reduce((s, a) => s + a, 0)/2)) + ' seats needed for majority');
    }

    const getMenu = () => {
        return (<a href={encodeURI("https://twitter.com/intent/tweet?text=https://statostuff.com/" + getShort(country) + '/result')} target="twitter">
            <img className="hoverable" width={24} src='/twitterLogo2.png' alt='tweet' />
        </a>);
    }
    const getChildren = (isDefault) => {
        return (<>
            <img className={"floatLeft " + (isDefault ? " collapsed" : "")} src="/Statostuff.png" alt="Statostuff" />
                <BarGraph bgColor={isDefault ? '#333' : '#fff'} title={getTitle()} labels={countryParties.map(x => x.party + '\n(' + x.group + ")")}
                    dataNow={data} />
        </>)
    }
    const getChildrenSeats = (isDefault) => {
        return (<>
            <img className={"floatLeft absolute " + (isDefault ? " collapsed" : "")} src="/Statostuff.png" alt="Statostuff" />
            <div>
                <SemiCircleGraph bgColor={isDefault ? '#333' : '#fff'} title={country + " expected seats"} dataNow={seatsData} labels={countryParties.map(x => x.party + "\n(" + x.group + ")")} height={360} />
            </div>
        </>)
    }

    return (
        <div className="countryDiv">
            <br />
            <span className="highlight groupNi">live <span className="dot groupSD" /></span>
            
            <div>
                <Picturable getMenu={getMenu} getChildren={getChildren} createText={createText} downloadName={`poll_${country}_prediction.png`} />
            </div>
            <br />
            { counted > 0 && 
            <>
                <span className="underline">current result ({counted}% counted)</span>
                <div className="margin12">
                    {countryParties.map((x, i) => <span key={x.party}><span className={"highlight " + styleByGroup(x.group)}>{x.party}:&nbsp;{currentResult ? (currentResult[i] ?? 0) : 0}%</span>{space}</span>)}
                </div>
            </>
            }
            <span className="underline">prediction</span>
            <div className="margin12">
                {countryParties.map((x, i) => <span key={x.party}><span className={"highlight " + styleByGroup(x.group)}>{x.party}:&nbsp;{Math.round(data[i] * 10)/10}%</span>{space}</span>)}
            </div>
            <span className="underline">last election ({lastElectionData["year"]})</span>
            <div className="margin12">
                {countryParties.map((x, i) => <span key={x.party}><span className={"highlight " + styleByGroup(x.group)}>{x.party}:&nbsp;{lastElectionData[x.party] ?? 0}%</span>{space}</span>)}
            </div>
            <hr />
            <span className="underline">expected seats</span>
            <div className="margin12">
                {countryParties.map((x, i) => <span key={x.party}><span className={"highlight " + styleByGroup(x.group)}>{x.party}:&nbsp;{seatsData[i] ?? 0}</span>{space}</span>)}
            </div>
            <div className="margin12">
                <Picturable getMenu={getMenu} getChildren={getChildrenSeats} createText={() => createText(1)} downloadName={`poll_${country}_seats_prediction.png`} />
            </div>
            <hr />
            <div className="margin8">
                {ratingDiv(0, 0, 1)}
                &nbsp;Current ratings are calculated from prediction data until we get final result.
            </div>
            {polls.slice(0, 20).filter((x, i, arr) => arr.map(y => y.firm).indexOf(x.firm) === i).map(x => <div key={x.firm} className="margin12">
                <span className="highlight groupNi">{x.firm}</span>{ratingDiv(pollQuality(x, data, countryParties), 0.1, 2.5)}
                <div className="margin8">{countryParties.map((y, j) => {
                    const val = x[y.party];
                    return val ? <span key={y.party}><span className={"highlight " + styleByGroup(y.group)}>{y.party}:&nbsp;{val[0] === '?' ? val?.substring(1) + ' seats' : val + '%'}</span>{space}</span> : null})}</div>
            </div>)}
            <hr />
            Our prediction model uses the most recent polls for prediction but as we receive currect election results they get higher and higher weight. We also do some adjustments to the early live data since they may be influenced by regional and local trends and therefore they differ from final results.
        </div>
    );
}