import { MainPage } from "./pages/MainPage"
import { Link, Route, BrowserRouter as Router, RouterProvider, Routes, createBrowserRouter } from "react-router-dom"
import { TopPage } from "./pages/TopPage";
import parties from './data/parties.json';
import euPolitics from './data/euPolitics5.json';
import { getCookieCount } from "./items/CookieData";
import { pollsFunc } from "./calculations/calc";
import { useEffect, useState } from "react";

export const Providers = () => {
    const [selectedCountry, setSelectedCountry] = useState('All');
    const indexStart = window.location.href.lastIndexOf('/');
    const selectedShort = indexStart > 0 ? window.location.href.substring(indexStart + 1) : "all";
    const pollsFunc = (country) => euPolitics.filter(x => x.country === country)[0]?.polls;

    const countryList = parties.map(x => x.short).filter(x => x && !["UK", "CY"].includes(x));
    countryList.splice(0, 0, "EU");
    countryList.splice(0, 0, "All");
    const convertName = (short) => { 
        return parties.find(x => x.short === short)?.country;
    }
    const getCount = (short) => {
        const count = getCookieCount(short, pollsFunc(convertName(short))?.slice(0, 10));
        if (count === 0) { return <></> }
        return <span className="bubble">{count}</span>
    }
    if (!selectedCountry.includes('EU', 'All') && selectedCountry) {
        const found = convertName(selectedCountry);
        if (found) {
            document.title = 'Polls and election statistics from ' + found + ' - Statostuff';
        } else {
            document.title = 'Statostuff';
        }
    } else {
        document.title = 'Statostuff';
    }

    return (<div>
            <Router>
                <div className="underline width180">
                    <img src={"/favicon.ico"} alt="Statostuff" height={14} /> &nbsp;
                    <b>Statostuff</b> &nbsp;
                    <span className="dot groupG" />&nbsp;
                    <span className="dot groupSD" />
                </div>

                <div>
                    <div className="wrap">
                        {countryList.map(x => (
                            <div key={x} className="headerCountry" onClick={() => setSelectedCountry(y => x)}>
                                <Link key={x} to={x}>
                                    <span className={x === selectedCountry ? "selected" : "hoverable"}>
                                        <img src={"/flags/" + x.toLowerCase() + ".png"} alt={x} height={14} />
                                        &nbsp;{x}&nbsp;
                                    </span>
                                    {getCount(x)}
                                </Link>
                                |&nbsp;
                            </div>
                        ))}

                        <Routes>
                            {countryList.map(x => <Route key={x} path={x} element={<SelectElement setSelectedCountry={setSelectedCountry} short={x} />} />)}
                            {countryList.map(x => <Route key={x+"/result"} path={x+"/result"} element={<SelectElement setSelectedCountry={setSelectedCountry} short={x+"/result"} />} />)}
                            <Route path="/" element={<SelectElement setSelectedCountry={setSelectedCountry} short='All' />} />

                            <Route path="/ES/result" element={<SelectElement setSelectedCountry={setSelectedCountry} short='ES/result' />} />
                        </Routes>
                    </div>
                    <MainPage />
                </div>
            </Router>
        </div>)
}

const SelectElement = (props) => {
    const { setSelectedCountry, short } = props;

    useEffect(() => {
        setSelectedCountry(x => short);
    }, [short]);

    return <div />
}