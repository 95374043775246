import { GROUPS, generate } from "../calculations/calc";
import { getClass } from "../calculations/calculationDivs";

export const DotMap = (props) => {
    const { groupCounts, oldCounts} = props;
    const counts = GROUPS.map((x, i) => groupCounts.reduce((s, a) => s + a.data[i], 0));

    return (
    <div>
        <div className="flex">
            {GROUPS.slice(0, 7).map((x, i) => DotItem(counts, i))}
        </div>
        <div className="flex">
            {GROUPS.slice(0, 7).map((x, i) => SumItem(counts, i, oldCounts))}
        </div>
        <hr />
    </div>
    )
}

const SumItem = (counts, index, oldCounts) => {
    const group = GROUPS[index];
    const diff = counts[index] - oldCounts[index];
    return (
        <div key={index} className="group-column">
            <div className="highlight">{group}<span className="floatRight">{diff > 0 ? "+" : ""}{diff}</span></div>
        </div>
    )
}

const DotItem = (counts, index) => {
    const group = GROUPS[index];
    const className = getClass(group);
    return (
        <div key={index} className="group-column">
            <div className={"highlight " + className}>{group}<span className="floatRight">{counts[index]}</span></div>
            {Dot(counts[index], className)}
        </div>
    )
}

const Dot = (count, group) => {
    return generate(count).map((x, i) => <span key={i} className={"dot " + group} />);
}
