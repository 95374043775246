export const styleByGroup = (group) => {
    switch (group) {
        case "Left": return "groupLeft";
        case "G": return "groupG";
        case "S&D": return "groupSD";
        case "RE": return "groupRe";
        case "EPP": return "groupEpp";
        case "ECR": return "groupEcr";
        case "ID": return "groupId";
        case "NI":
        default: return "groupNi";
    }
}

export const getColorByGroup = (group) => {
    switch (group) {
        case "Left": return "#a00";
        case "G": return "#4a4";
        case "S&D": return "#f00";
        case "RE": return "#990";
        case "EPP": return "#3af";
        case "ECR": return "#00f";
        case "ID": return "#009";
        case "NI":
        default: return "#888";
    } 
}
export const getLightColorByGroup = (group) => {
    switch (group) {
        case "Left": return "#a66";
        case "G": return "#9f9";
        case "S&D": return "#f88";
        case "RE": return "#ff6";
        case "EPP": return "#8ef";
        case "ECR": return "#88f";
        case "ID": return "#669";
        case "NI":
        default: return "#ccc";
    } 
}
export const getColorByGroupScore = (group, score) => {
    let index = -1;
    if (score >= 40) { index = 0 }
    else if (score >= 30) { index = 1; }
    else if (score >= 20) { index = 2; }
    else if (score >= 10) { index = 3; }
    else if (score >= 5) { index = 4; }
    else if (score > 0) { index = 5; }
    switch (group) {
        case "Left": return index === -1 ? null : ["#700", "#900", "#c00", "#f00", "#f22", "#f44"][index];
        case "G": return ["#171", "#393", "#5b5", "#7d7", "#9e9", "#bfb"][index];
        case "S&D": return ["#f00", "#f11", "#f33", "#f55", "#f77", "#f99"][index];
        case "RE": return ["#990", "#aa1", "#cc3", "#dd4", "#ee5", "#ff8"][index];
        case "EPP": return ["#2bf", "#4cf", "#6df", "#7ef", "#aff", "#cff"][index];
        case "ECR": return ["#00f", "#22f", "#44f", "#66f", "#88f", "#aaf"][index];
        case "ID": return ["#009", "#11a", "#22b", "#33c", "#44d", "#66f"][index];
        case "NI":
        default: return ["#888", "#999", "#aaa", "#bbb", "#ccc", "#ddd"][index];
    } 
}

export const getColorByLeftRightScore = (score) => {
    const colors = ["#900", "#a11", "#c22", "#e44", "#f77", "#ddd", "#77f", "#44e", "#22c", "#11a", "#009"];
    if (score > 50) {
        return colors[0];
    } else if (score > 30) {
        return colors[1];
    } else if (score > 10) {
        return colors[2];
    } else if (score > 5) {
        return colors[3]
    } else if (score > 1) {
        return colors[4];
    } else if (score > -1) {
        return colors[5];
    } else if (score > -5) {
        return colors[6];
    } else if (score > -10) {
        return colors[7];
    } else if (score > -30) {
        return colors[8];
    } else if (score > -50) {
        return colors[9];
    } else {
        return colors[10];
    }
}
export const getColorByLibConScore = (score) => {
    const colors = ["#990", "#aa1", "#cc2", "#ee4", "#ff7", "#ddd", "#8ff", "#7ef", "#6df", "#4cf", "#2bf"];
    if (score > 50) {
        return colors[0];
    } else if (score > 30) {
        return colors[1];
    } else if (score > 10) {
        return colors[2];
    } else if (score > 5) {
        return colors[3]
    } else if (score > 1) {
        return colors[4];
    } else if (score > -1) {
        return colors[5];
    } else if (score > -5) {
        return colors[6];
    } else if (score > -10) {
        return colors[7];
    } else if (score > -30) {
        return colors[8];
    } else if (score > -50) {
        return colors[9];
    } else {
        return colors[10];
    }
}
export const getGrayColorByScore = (score) => {
    const colors = ["#fff", "#eee", "#ddd", "#ccc", "#bbb", "#aaa", "#999", "#888", "#666", "#444"];
    for (let i = 0; i < colors.length; ++i) {
        if (score > 90 - 10 * i) {
            return colors[i];
        }
    }
    return null;
}

export const getColorByLikelihood = (likelihood) => {
    const colors = ["#777", "#555", "#444", "#f66", "#f11"];
    if (likelihood >= 90) {
        return colors[0];
    } else if (likelihood >= 55) {
        return colors[1];
    } else if (likelihood >= 40) {
        return colors[2];
    } else {
        return colors[3];
    }
}
export const getTextByLikelihood = (likelihood) => {
    const texts = ["very likely", "likely", "probable", "not much likely"];
    if (likelihood >= 90) {
        return texts[0];
    } else if (likelihood >= 55) {
        return texts[1];
    } else if (likelihood >= 40) {
        return texts[2];
    } else {
        return texts[3];
    }
}

export const getIndexOfMax = (arr) => {
    return arr.indexOf(Math.max(...arr));
}

export const parseFloat = (num) => {
    return !Number.isNaN(Number.parseFloat(num)) ? num : 0;
}

export const getAllCombinationsOfCount = (size, ofCount) => {
    const res = [];
    const length = Math.pow(2, size);
    for (var i = 0; i < length; ++i) {
        const curr = [];
        for (var j = 0; j < size; ++j) {
            if (i & Math.pow(2, j)){
                curr.push(j);
            }
        }
        if (curr.length === ofCount) {
            res.push(curr);
        }
    }
    return res;
}

export const space = <>&#32;</>;