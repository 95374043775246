import euPolitics from '../data/euPolitics5.json';
import parties from '../data/parties.json';
import nextElection from '../data/nextElection.json';

import { getSumByParty } from './calculationDivs';

export const GROUPS = ["Left", "G", "S&D", "RE", "EPP", "ECR", "ID", "NI"];

// adjust seats for a country because there are minor parties that will likely be elected but have very low poll numbers
const adjustment = [{"country": "Germany", "adj": 9},
    {"country": "Spain", "adj": 4}
];

const ni_adjust = [{"party": "M5S", "group": "G"},{"party": "Fidesz–KDNP", "group": "ECR"}];

export const DHondt = (votes, seatCount, threshold = 0) => {
    const seats = votes.map(x => { return 0; });

    for (let j = 0; j < seatCount; ++j) {
        const newVotes = votes.map((x, i) => x >= threshold ? 10000 * x / (seats[i] + 1) : 0);
        const max = Math.max(...newVotes);
        const maxIndex = newVotes.indexOf(max);
        seats[maxIndex] += 1;
    }
    return seats;
}

export const politicData = euPolitics;
export const getCountryData = (country) => euPolitics.find(x => x.country === country);

export const govPerc = (country) => {
    const euFiltered = euPolitics.filter(x => x.country === country)[0];
    const govParties = euFiltered.governments.length > 0 ? euFiltered.governments[0].parties : [];
    const govs = countryPartiesFunc(country).filter(x => govParties?.includes(x.party));
    const seats = getPredictionSeats(country, true);
    const partyList = countryPartiesFunc(country).map(x => x.party);
    const parliament = partyFiltered(country).parliament;
    return getSumByParty(govs.map(x => x.party), seats, partyList) / parliament;
}

//console.log(new URLSearchParams(useLocation().search).get("get"));
export const getPartiesFunc = (country) => parties.find(x => x.country === country || x.region === country)?.parties?.filter(x => !(x.filtered ?? false))
    ?? parties.find(y => y.country === parties.find(x => x.region === country).country)?.parties?.filter(x => !(x.filtered ?? false));
export const getAllPartiesFunc = (country) => parties.find(x => x.country === country || x.region === country)?.parties
    ?? parties.find(y => y.country === parties.find(x => x.region === country).country)?.parties;
export const lastElectionDateFunc = (country) => nextElection.filter(x => new Date(x.date) < new Date()).findLast(x => x.country === country)?.date;
const hasLastElectionResultFunc = (country) => euPolitics.find(x => x.country === country && new Date().getFullYear() === x.year);
const lastElectionResultFunc = (country) => euPolitics.filter(x => x.country === country)[0].elections[0];
export const  pollsFunc = (country) => euPolitics.find(x => x.country === country)?.polls;
const pollsCurrentFunc = (country) => { 
    const lastElectionDate = lastElectionDateFunc(country);
    const result = euPolitics.filter(x => x.country === country)[0].polls
        .filter((x, i) => !lastElectionDate || new Date(x.dateJs) > new Date(lastElectionDate));
    const res = result && result.length > 0 ? result : 
        hasLastElectionResultFunc(country) ? [lastElectionResultFunc(country)] : pollsFunc(country);
    return res;
}
export const partyFiltered = (country) => parties.find(x => x.country === country && !x.region)
    ?? parties.find(x => x.region === country);
const countryPartiesFunc = (country) => getPartiesFunc(country); //partyFiltered(country).parties.filter(x => !(x.filtered ?? false));
const countryAllPartiesFunc = (country) => getAllPartiesFunc(country);
const parliamentSeatsFunc = (country) => partyFiltered(country).parliament;
const euSeatsFunc = (country) => partyFiltered(country).seats;
const thresholdFunc = (country) => partyFiltered(country).threshold;
const noEuThresholdFunc = (country) => partyFiltered(country).noEuThreshold;

export const getChangeSinceElection = (country) => {
    const data = getPredictionSimple(country, true);
    
    const euFiltered = euPolitics.filter(x => x.country === country)[0];
    const lastElection = euFiltered.elections[0];
    const lastElectionData = countryPartiesFunc(country)
        .map(x => lastElection[x.party] ?? 0);
    return countryPartiesFunc(country)
        .map((x, i) => lastElectionData[i] === 0 ? 0 : Math.round(data[i] - lastElectionData[i]));
}
// onlyCurrent: means ignore polls before the last election
export const getPredictionSimple = (country, onlyCurrent) => {
    const firms = [];
    const weights = [];
    let result = [];
    let count = 1;
    let avgWeight = 1;
    
    const polls = onlyCurrent ? pollsCurrentFunc(country) : pollsFunc(country);
    const countryParties = countryPartiesFunc(country);
    for (let i = 0; i < polls.length; ++i) {
        const poll = countryParties.map(y => y.party).map(y => polls[i][y] ?? 0);
        if (result.length === 0) {
            result = poll.map(x => isNaN(x) ? 0 : x);
            firms.push(polls[i].firm);
            weights.push(0.6);
        } else {
            let weight = 1;
            if (firms.includes(polls[i].firm)) {
                const index = firms.indexOf(polls[i].firm);
                weight = weights[index];
                weights[index] *= 0.5;
                avgWeight = (avgWeight * count + weight)/(count + 1);
            } else {
                weight = avgWeight;
                firms.push(polls[i].firm);
                weights.push(weight * 0.6);
            }
            for (let j = 0; j < result.length; ++j) {
                const val = isNaN(poll[j]) ? 0 : poll[j];
                result[j] = (result[j] * count + val * weight) / (count + (isNaN(poll[j]) ? 0 : weight));
            }
            ++count;
        }
    }
    const weight = ["Netherland", "Belgium"].includes(country) ? 15 : 10; // Weight 15 because of 150 seats in the parliament and polls use seats there instead of perc
    return result.map(x => Math.round(x * 10) / weight);
}
export const getPrediction = (country) => {
    const data = getPredictionSimple(country);

    const result = groupByGroup(data, country);

    return result;
}
export const getPredictionSeats = (country, isParliament) => {
    const result = getPredictionSimple(country, true);

    const seats = getSeats(result, country, isParliament);
    return seats;
}
export const getPredictionSeatsByGroup = (country) => {
    const result = getPredictionSeats(country, false);
    return groupByGroup(result, country);
}
export const getEuSeatsByGroup = (country) => {
    const euFiltered = euPolitics.filter(x => x.country === country)[0];
    const lastEuElection = euFiltered.euElectionsSeats[0];
    const result = countryPartiesFunc(country)
        .map(x => lastEuElection[x.party] ?? 0);

    return groupByGroup(result, country);
}
export const getSeats = (result, country, isParliament) => {
    
    const subtractAdj = (country) => !isParliament && adjustment.map(x => x.country).includes(country) ? adjustment.filter(x => x.country === country)[0].adj : 0;
    const seats = isParliament ? parliamentSeatsFunc(country) 
        : euSeatsFunc(country) - subtractAdj(country);
    const threshold = thresholdFunc(country);
    const noEuThreshold = noEuThresholdFunc(country);

    return DHondt(result, seats, noEuThreshold && !isParliament ? 0 : (threshold ?? 0));
}

export const groupByGroup = (data, country) => {
    const countryParties = countryPartiesFunc(country);

    return GROUPS.map(x => countryParties.map((y, i) => { return ({group: y.group, index: i}); })
        .filter(y => y.group === x).reduce((s, a) => s + (data[a.index] ?? 0), 0))
        .map(x => Math.round(x * 10) / 10);
}
export const groupByGroupAdj = (data, country) => {
    const countryParties = countryPartiesFunc(country);

    const replaceGroup = (party) => {
        // Replace NI group by group from adjustment config
        if (countryParties.find(x => ni_adjust.find(y => y.party === x.party))) {
            return ni_adjust.find(x => x.party === party)?.group ?? countryParties.find(x => x.party === party).group;
        } else { return countryParties.find(x => x.party === party).group; }
    }

    return GROUPS.map(x => countryParties.map((y, i) => { return ({group: replaceGroup(y.party), index: i}); })
        .filter(y => y.group === x).reduce((s, a) => s + (data[a.index] ?? 0), 0));
}

const sumByGroup = (groupsUsed, data) => {
    return data.filter((x, i) => groupsUsed.includes(GROUPS[i])).reduce((s, a) => s + a, 0);
}
export const leftRightScore = (country) => {
    const result = getPredictionSimple(country);
    const byGroup = groupByGroupAdj(result, country);
    const left = sumByGroup(["Left", "G", "S&D"], byGroup);
    const right = sumByGroup(["EPP", "ECR", "ID"], byGroup);
    return Math.round((left - right) * 10)/10;
}
export const libConScore = (country) => {
    const result = getPredictionSimple(country);
    const byGroup = groupByGroupAdj(result, country);
    const lib = sumByGroup(["Left", "G", "S&D", "RE"], byGroup);
    const con = sumByGroup(["EPP", "ECR", "ID"], byGroup);
    return Math.round((lib - con) * 10)/10;
}

export const getShort = (country) => {
    return parties.find(x => x.country === country)?.short
        ?? parties.find(y => y.country === parties.find(x => x.region === country)?.country)?.short;
}
export const getShortCountry = (country) => {
    return parties.find(x => x.country === country)?.short;
}

export const pollQuality = (poll, result, countryParties) => {
    const err = countryParties.map((x, i) => Math.pow(poll[x.party] - result[i], 2));
    return Math.round(10 * (err.reduce((s, a) => s + (isNaN(a) ? 0 : a), 0))/err.filter(x => !isNaN(x)).length)/10;
}

export const generate = (count) => {
    let arr = [];
    for (let i = 0; i < count; ++i){
        arr.push(0);
    }
    return arr;
}
