import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MainPage } from './pages/MainPage';
import { TopPage } from './pages/TopPage';
import { Providers } from './Providers';
import { DataProvider } from './context/DataContext'
import { Helmet } from 'react-helmet';

/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TopPage />
  </React.StrictMode>
);*/

const main = ReactDOM.createRoot(document.getElementById('main'));
main.render(
  <DataProvider>
    <Helmet>
      <meta name="description" content="European election poll data and statistics." />
    </Helmet>
    <Providers />
  </DataProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
