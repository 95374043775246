import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

export const BarGraph = (props) => {
    const { title, labels, dataNow, dataBefore, election, bgColor } = props;
    const background = bgColor ?? '#333';

    const plugin = {
        id: 'background',
        beforeDraw: (chart, args, opts) => {
          if (!opts.color) {
            return;
          }
      
          const {
            ctx,
            chartArea
          } = chart;
      
          ctx.fillStyle = opts.color;
          ctx.fillRect(chartArea.left, chartArea.top, chartArea.width, chartArea.height)
        }
      }

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        plugin,
        ChartDataLabels
      );
      
      const options = {
        plugins: {
          title: {
            display: true,
            text: title,
          },
          legend: { 
            display: false
          },
          background: { color: background },

          datalabels: {
            display: true,
            color: "#fff",
            formatter: Math.round,
            anchor: "end",
            offset: 2,
            align: "start",
          }
        },
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        scales: {
          x: {
            stacked: false,
          },
          y: {
            stacked: false,
          },
        },
        datasets: {
            bar: {
                barPercentage: 1.4,
                categoryPercentage: 0.60
            }
        }
      };
      if (background !== '#333'){ options.animation = { duration: 0 }; }
      
      const getGroup = (label) => {
        const group = label.split('\n')[1] ?? label;
        switch (group.toLowerCase()) {
            case '(left)': return 'left';
            case '(g)': return 'g';
            case '(s&d)': return 's&d';
            case '(re)': return 're';
            case '(epp)': return 'epp';
            case '(ecr)': return 'ecr';
            case '(id)': return 'id';
            case '(ni)': return 'ni';
            default: return '';
        }
      }
      const getColor = (label) => {
        const group = getGroup(label);
        switch (group) {
          case 'left': return '#a00';
          case 'g': return '#4a4';
          case 's&d': return '#f00';
          case 're': return '#990';
          case 'epp': return '#3af';
          case 'ecr': return '#00f';
          case 'id': return '#009';
          case 'ni': return '#999';
          default: return '#fff';
        }
      }
      const getColor2 = (label) => {
        const group = getGroup(label);
        switch (group) {
          case 'left': return '#a66';
          case 'g': return '#9f9';
          case 's&d': return '#f88';
          case 're': return '#cc3';
          case 'epp': return '#8ef';
          case 'ecr': return '#88f';
          case 'id': return '#669';
          case 'ni': return '#ccc';
          default: return '#fff';
        }
      }
      const getColor3 = (label) => {
        const group = getGroup(label);
        switch (group) {
          case 'left': return '#d99';
          case 'g': return '#bfb';
          case 's&d': return '#fcc';
          case 're': return '#ffa';
          case 'epp': return '#bff';
          case 'ecr': return '#aaf';
          case 'id': return '#99a';
          case 'ni': return '#eee';
          default: return '#fff';
        }
      }
      const datasets = dataBefore === undefined ?
      [{
        data: dataNow,
        backgroundColor: labels.map(x => getColor(x)),
        stack: '1',
      }]
      : (dataBefore?.reduce((a, b) => a + b, 0) === 0
        ? [
            {
              data: dataNow,
              backgroundColor: labels.map(x => getColor(x)),
              stack: '1',
            },
            {
              data: election,
              backgroundColor: labels.map(x => getColor3(x)),
              stack: '3',
              datalabels: { color: '#555' }
            },
          ]
        : [
        {
          data: dataNow,
          backgroundColor: labels.map(x => getColor(x)),
          stack: '1',
        },
        {
          data: dataBefore,
          backgroundColor: labels.map(x => getColor2(x)),
          stack: '2',
        },
        {
          data: election,
          backgroundColor: labels.map(x => getColor3(x)),
          stack: '3',
          datalabels: { color: '#555' }
        },
      ]);
      const data = {
        labels,
        datasets: datasets,
      };

    return (
        <div style={{backgroundColor: background}}>
            <Bar options={options} data={data} />
        </div>
    )
}
