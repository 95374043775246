import { styleByGroup } from "../calculations/utils";

export const GraphHeader = (props) => {
    const { curr, prev, parties, noThreshold } = props;


    const old = curr.dateJs ? Math.floor((new Date() - new Date(curr.dateJs)) / (1000*60*60*24)) : null;
    const days = prev === undefined || !curr.dateJs ? undefined : Math.floor((new Date(curr.dateJs) - new Date(prev.dateJs)) / (1000*60*60*24));
    const daysAgo = () => {
        if (days === undefined) { return <span /> }
        else if (days <= 7) {
            return <span className="highlight hlFirst">less than a week ago</span>;
        } else if (days <= 14) {
            return <span className="highlight hlSecond">a week ago</span>;
        } else if (days <= 21) {
            return <span className="highlight hlSecond">two weeks ago</span>;
        } else if (days <= 28) {
            return <span className="highlight hlThird">three weeks ago</span>;
        } else if (days <= 60) {
            return <span className="highlight hlFourth">a month old</span>;
        } else {
            return <span className="highlight hlFourth">old</span>;
        }
    }
    const currDate = () => {
        if (!curr.date) { return null; }
        if (old <= 7) {
            return <span className="highlight hlFirst">{curr.date}</span>;
        } else if (old <= 14) {
            return <span className="highlight hlSecond">{curr.date}</span>;
        } else if (old <= 21) {
            return <span className="highlight hlThird">{curr.date}</span>;
        } else {
            return <span className="highlight hlFourth">{curr.date}</span>;
        }
    }

    const partyDiff = (party) => Math.round(10 * (curr[party] - (prev[party] ?? 0)))/10;
    const diffs = prev === undefined ? undefined 
        : parties.map(x => ({ diff: partyDiff(x.party), group: x.group, party: x.party })).filter(x => x.diff && (noThreshold || Math.abs(x.diff) >= 0.5));
    diffs?.sort((a, b) => b.diff - a.diff);
    const changes = () => {
        return diffs?.map((x, i) => <span key={i} className={"tooltip highlight " + styleByGroup(x.group)}>
            <span className="tooltipText">{x.party}</span>
            {x.diff > 0 ? '+' : ''}{x.diff}
        </span>)
    }

    return <div>
        {currDate()}
        {daysAgo()}
        { curr.firm && 
            <span className="highlight groupNi">{curr.firm}</span>
        }
        {changes()}
    </div>
}