import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

export const LineGraph = (props) => {
    const { labels, dataItems, parties, title } = props;

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );
    
const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      datalabels: {
        display: false,
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
  
  const getGroup = (label) => {
    const group = label?.split('\n')[1] ?? label;
    switch (group?.toLowerCase()) {
        case '(left)': return 'left';
        case '(g)': return 'g';
        case '(s&d)': return 's&d';
        case '(re)': return 're';
        case '(epp)': return 'epp';
        case '(ecr)': return 'ecr';
        case '(id)': return 'id';
        case '(ni)': return 'ni';
        default: return '';
    }
  }
  const getColor = (label) => {
    const group = getGroup(label);
    switch (group) {
      case 'left': return '#a00';
      case 'g': return '#4a4';
      case 's&d': return '#f00';
      case 're': return '#990';
      case 'epp': return '#3af';
      case 'ecr': return '#00f';
      case 'id': return '#009';
      case 'ni': return '#999';
      default: return '#fff';
    }
  }
  
    const lineData = {
        labels,
        datasets: dataItems.map((x, i) => ({
                label: parties[i],
                data: x,
                backgroundColor: getColor(parties[i]),
                borderColor: getColor(parties[i]),
            })),
      };

    return <Line options={options} data={lineData} />;
}